import axios from "@/axios/axiosConfig";

export const listHardwareBrand = () => {
  return axios({
    url: "/hardwareBrand/listHardwareBrand",
    method: "post"
  });
};

export const searchHardwareBrand = (params) => {
  return axios({
    url: "/hardwareBrand/searchHardwareBrand",
    method: "post",
    data: params
  });
};

export const listMedicineBrand = () => {
  return axios({
    url: "/medicineBrand/listMedicineBrand",
    method: "post"
  });
};

export const searchMedicineBrand = (params) => {
  return axios({
    url: "/medicineBrand/searchMedicineBrand",
    method: "post",
    data: params
  });
};

export const searchCustomer = (params) => {
  return axios({
    url: "/customer/searchCustomer",
    method: "post",
    data: params
  });
};

export const queryMyCustomerList = () => {
  return axios({
    url: "/customer/queryMyCustomerList",
    method: "get"
  });
};

export const searchStaff = (params) => {
  return axios({
    url: `/staff/searchStaff/${params.customerId}/${params.keyWord}`,
    method: "post"
  });
};

export const searchHardware = (params) => {
  return axios({
    url: `/hardware/searchHardware/${params.customerId}/${params.keyWord}`,
    method: "get"
  });
};

export const searchMedicine = (params) => {
  return axios({
    url: `/medicine/searchMedicine/${params.customerId}/${params.keyWord}`,
    method: "get"
  });
};

export const searchTrainingMaterials = (params) => {
  return axios({
    url: "/trainingMaterials/searchTrainingMaterials",
    method: "post",
    data: params
  });
};
